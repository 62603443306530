<template>
  <section :class="showUserProfileMessaging ? 'has-2-columns' : 'has-1-column'">
    <user-profile
      :user-id="userId"
      :preloadedAssessment="preloadedAssessment"
      layout="page"
    />
    <div
      v-if="showUserProfileMessaging"
      class="user-profile-page-messaging-container"
    >
      <user-profile-messaging layout="page" />
    </div>
  </section>
</template>

<script>
import UserProfileMessaging from "@/components/user-profile/UserProfileMessaging.vue";
import UserProfile from "@/components/user-profile/UserProfile.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfilePage",
  components: { UserProfile, UserProfileMessaging },
  props: {
    userId: {
      type: String
    },
    preloadedAssessment: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      adminCanSendMessageToEndUser: "adminCanSendMessageToEndUser",
      adminId: "userId"
    }),
    showUserProfileMessaging() {
      return this.adminCanSendMessageToEndUser && this.userId !== this.adminId;
    }
  },
  mounted() {
    document.body.classList.add("is-user-profile-page");
  },
  beforeDestroy() {
    document.body.classList.remove("is-user-profile-page");
  }
};
</script>
<style scoped lang="scss">
section {
  border-top: 1px solid var(--vfl-border-color);
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - var(--topbar-height));
  overflow: hidden;

  &.has-1-column {
    margin: 0 auto;
    max-width: 800px;
  }

  &.has-2-columns {
    grid-template-columns: 1fr 1fr;
  }
}

.user-profile-page-messaging-container {
  border-left: 1px solid var(--vfl-border-color);
  height: calc(100vh - var(--topbar-height));
}
</style>
