export const Theme = {
  WEBCAM: "webcam",
  PAIN: "pain",
  SETUP: "setup",
  HEALTH: "health",
  REPORT: "report"
};

export const StepId = {
  WELCOME: "welcome",
  GOAL_SELECTION: "goalSelection",
  WEBCAM_INSTRUCTIONS: "webcamInstructions",
  WEBCAM: "webcam",
  PAIN: "pain",
  WORKHABITS: "workhabits",
  SIMS: "sims",
  ELEVATION_PLANNER: "elevationPlanner",
  HEIGHT: "height",
  SEATING: "seating",
  EQUIPMENT: "equipment",
  HEALTH: "health",
  REPORT: "report"
};

export const assessmentSteps = {
  welcome: {
    id: StepId.WELCOME,
    navigation: {
      showStepper: false,
      showNavigation: false
    },
    validation: false
  },
  goalSelection: {
    id: StepId.GOAL_SELECTION,
    navigation: {
      showStepper: false,
      showNavigation: false
    },
    validation: false,
    background: {
      backgroundColor: "var(--v-vflBeigeLight1-base)"
    }
  },
  webcamInstructions: {
    id: StepId.WEBCAM_INSTRUCTIONS,
    theme: Theme.WEBCAM,
    navigation: {
      showStepper: false,
      showNavigation: true,
      canSkip: true
    },
    background: {
      backgroundColor: "#F0F4FF"
    }
  },
  webcam: {
    id: StepId.WEBCAM,
    theme: Theme.WEBCAM,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.webcam.title"
    },
    background: {
      backgroundColor: "#F0F4FF"
    }
  },
  pain: {
    id: StepId.PAIN,
    theme: Theme.PAIN,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.pain.title"
    },
    form: true,
    background: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 550px, #DBEEEF 550px)"
    }
  },
  workhabits: {
    id: StepId.WORKHABITS,
    theme: Theme.SETUP,
    image: "setup_habits-1.svg",
    blobColor: "#F5F9FE",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    background: {
      backgroundImage:
        "linear-gradient(to bottom, #F8F5F0 560px, #EEE8DF 560px)"
    }
  },
  sims: {
    id: StepId.SIMS,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    background: {
      backgroundColor: "#F0F4FF"
    }
  },
  elevationPlanner: {
    id: StepId.ELEVATION_PLANNER,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true
    },
    background: {
      backgroundColor: "#F0F4FF"
    }
  },
  height: {
    id: StepId.HEIGHT,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true
    },
    background: {
      backgroundImage:
        "linear-gradient(to bottom, #F0F4FF 494px, #E4EBFF 494px)"
    }
  },
  seating: {
    id: StepId.SEATING,
    theme: Theme.SETUP,
    image: "setup_habits-2.svg",
    blobColor: "#F6FEEB",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    background: {
      backgroundImage:
        "linear-gradient(to bottom, #F7FBF3 450px, #DEE6D7 450px)"
    }
  },
  equipment: {
    id: StepId.EQUIPMENT,
    theme: Theme.SETUP,
    image: "setup_habits-3.svg",
    blobColor: "#E9F5F5",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    background: {
      backgroundColor: "#FFF5E9"
    }
  },
  health: {
    id: StepId.HEALTH,
    theme: Theme.HEALTH,
    image: "health-2.svg",
    blobColor: "#FFFDE5",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "assessment.form.health.shortTitle"
    },
    form: true,
    background: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 445px, #DBEEEF 445px)"
    }
  },
  report: {
    id: StepId.REPORT,
    navigation: {
      showStepper: false,
      showNavigation: false
    }
  }
};

export const STEP_ORDER = Object.keys(assessmentSteps);

export const getStepIndex = stepId => {
  return STEP_ORDER.findIndex(step => step === stepId);
};

export const getSchemaPageFromId = (schema, id) => {
  const sections = Object.keys(schema);

  for (const sectionKey of sections) {
    const section = schema[sectionKey];

    if (section.pages) {
      const page = section.pages.find(page => page.name === id);

      if (page) {
        return page;
      }
    }
  }

  return null;
};

export const PRE_ASSESSMENT_STEPS = [StepId.WELCOME, StepId.GOAL_SELECTION];

export const isPreAssessmentStep = stepId =>
  PRE_ASSESSMENT_STEPS.includes(stepId);

export const getFirstAssessmentStepIndex = () =>
  STEP_ORDER.findIndex(step => !isPreAssessmentStep(assessmentSteps[step].id));
