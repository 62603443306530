<template>
  <div class="vfl-card-light pa-8 align-center d-md-flex">
    <img :src="imagePath" alt="" />
    <div>
      <slot name="text"></slot>
      <did-not-know-that-fact-button
        class="mt-8"
        :fact="fact"
        :assessmentType="assessmentType"
      />
    </div>
  </div>
</template>

<script>
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";

export default {
  name: "ReportSectionCard",
  components: {
    DidNotKnowThatFactButton
  },
  props: {
    imagePath: {
      type: String,
      required: true
    },
    fact: {
      type: String,
      required: true
    },
    assessmentType: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.vfl-card-light {
  background: white;
  gap: 1rem;
}
</style>
