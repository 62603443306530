<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>
      <slot name="section-title">
        {{ sectionTitle }}
      </slot>
    </template>

    <template #content>
      <div class="content-wrapper d-flex flex-wrap">
        <div
          class="vfl-card-light py-4 px-8 pb-12 flex-grow-1 pain-person-card"
        >
          <h4 class="text-h6 text-center mb-8 text-body-darkest">
            <slot name="pain-area-title">
              {{ painAreaTitle }}
            </slot>
          </h4>
          <pain-person
            v-model="results.pain"
            disabled
            :painPersonMaxWidth="280"
          />
        </div>

        <template v-if="visibleCards.length > 0">
          <report-info-card
            v-for="(card, index) in visibleCards"
            :key="card.title"
            :class="card.className"
            :title="card.title"
            :illustration="card.illustration"
            :text="card.text"
            :assessmentType="assessmentType"
            :use-html="card.useHtml"
            :image-background-colour="imageBackgroundColour"
            :image-width="imageWidth"
          />
        </template>
      </div>

      <slot name="warning"></slot>

      <recommendation-container
        class="mt-10"
        :title="recommendationTitle"
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="painRecommendations"
      />
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import RecommendationContainer from "@/components/assessment/common/report/RecommendationContainer.vue";

export default {
  name: "BaseReportPainSection",
  components: {
    PainPerson,
    ReportSection,
    ReportInfoCard,
    RecommendationContainer
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    assessmentType: {
      type: String,
      required: true
    },
    sectionTitle: {
      type: String,
      required: true
    },
    painAreaTitle: {
      type: String,
      required: true
    },
    infoCards: {
      type: Array,
      default: () => []
    },
    recommendationTitle: {
      type: String,
      required: true
    },
    painRecommendations: {
      type: Object,
      default: () => ({})
    },
    imageBackgroundColour: {
      type: String,
      default: ""
    },
    imageWidth: {
      type: Number,
      default: 120
    }
  },
  computed: {
    visibleCards() {
      return this.infoCards.filter(card => card?.showCondition === true);
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrapper {
  gap: 1rem;

  > div {
    flex: 1 0 400px;
  }

  // If only 2 cards, then make the info cards take up 75%
  &:has(> :nth-child(2):last-child) {
    > div:not(.pain-person-card) {
      flex-grow: 4;
    }
  }
}

.vfl-card-light {
  background: white;
}
</style>
