<template>
  <div
    :class="[
      'pain-form-container',
      { 'is-two-col-layout': isV2Layout },
      { 'is-v2-design': isV2Layout }
    ]"
  >
    <div
      :class="[
        'pain-person-container',
        { 'is-v2-design': isV2Layout },
        { 'has-error': showError }
      ]"
    >
      <div class="pain-person-sticky-container">
        <div class="pain-person-container-inner">
          <div v-if="showSelectBodyPartTitle" class="select-body-part-header">
            <h2 class="select-part">
              {{ $t("assessment.form.pain.selectBodyPart") }}
            </h2>
          </div>
          <pain-person
            class="ma-4"
            ref="painPerson"
            v-model="value"
            :linkedToQuestions="true"
            assessmentType="deskAssessment"
            :showWhyDoWeAskThisButton="false"
            :showTooltipInstruction="!isV2Layout"
            :painPersonMaxWidth="isV2Layout ? 350 : null"
            @noPainChanged="onNoPainChanged"
          />
        </div>
        <span
          v-if="showError"
          class="pain-form-error-message vflRed--text mt-2 d-inline-flex text-body-1 text-body-bold"
          >{{ $t("assessment.form.pain.error") }}</span
        >
      </div>
    </div>

    <pain-question-panels
      :questions="questions"
      class="pain-panels-section mb-4"
      ref="painAreaPanels"
      :results="fullResults"
      @removePainArea="$refs.painPerson.confirmRemovePainArea($event)"
      :assessmentType="assessmentType"
      :headerColor="panelHeaderColor"
      :contentColor="panelContentColor"
      :disable-question-numbering="disableQuestionNumbering"
    />
  </div>
</template>

<script>
import PainQuestionPanels from "@/components/deskassessment/PainQuestionPanels.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "PainForm",
  components: { PainPerson, PainQuestionPanels },
  props: {
    questions: Object,
    value: Object,
    fullResults: Object,
    assessmentType: {
      type: String,
      required: true
    },
    requireValidation: {
      type: Boolean,
      default: false
    },
    disableQuestionNumbering: Boolean
  },
  data() {
    return {
      showError: false
    };
  },
  computed: {
    isV2Layout() {
      return (
        this.assessmentType !== assessmentTypes.driverAssessment &&
        this.assessmentType !== assessmentTypes.physicalLabourAssessment
      );
    },
    showSelectBodyPartTitle() {
      return this.isV2Layout;
    },
    panelHeaderColor() {
      return this.isV2Layout ? "vflNeutralLight3" : "vitruePaleGrey";
    },
    panelContentColor() {
      return this.isV2Layout ? "transparent" : "";
    },
    hasValidSelection() {
      if (!this.$refs.painPerson) return false;

      const noPainSelected = this.$refs.painPerson.noPainCheckbox;
      const hasSelectedAreas = Object.keys(this.value?.areas || {}).length > 0;
      return noPainSelected || hasSelectedAreas;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        if (Object.keys(newVal?.areas || {}).length > 0) {
          this.showError = false;
        }
      }
    }
  },
  methods: {
    isValid() {
      if (!this.requireValidation) return true;

      const isValid = this.hasValidSelection;
      this.showError = !isValid;
      return isValid;
    },
    onNoPainChanged(value) {
      if (value) {
        this.showError = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$layout-change: 968px;

.pain-form-container {
  &.is-two-col-layout {
  }

  &.is-v2-design {
    display: contents;
    position: relative;
  }
}

.select-body-part-header {
  background-color: var(--v-vflNeutralLight2-base);
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.select-part {
  color: var(--v-vflGreen-base);
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}

.pain-person-container {
  position: relative;

  @media screen and (max-width: $layout-change) {
    margin-bottom: 4rem;
  }

  &.is-v2-design {
    grid-column: 1;
    grid-row: 1 / 999;

    .pain-person-sticky-container {
      position: sticky;
      top: calc(var(--topbar-height) + 48px); // space occupied by stepper
    }

    .pain-person-container-inner {
      background: white;
      border: 3px solid var(--v-vflGreen-base);
      border-radius: 12px;
      overflow: hidden;
    }

    &.has-error {
      .pain-person-container-inner {
        border-color: var(--v-vflRed-base);
      }
    }
  }
}

.pain-panels-section {
  grid-column: 2;
}

.pain-form-error-message {
  left: 0;
  position: absolute;
  top: 100%;
}
</style>
