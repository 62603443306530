<template>
  <div class="vfl-card-light">
    <div
      class="card-image px-8"
      :class="{ 'pt-5': !imageBackgroundColour }"
      :style="{ backgroundColor: imageBackgroundColour }"
    >
      <img
        :src="illustration"
        aria-hidden="true"
        alt=""
        :style="{ maxWidth: `${imageWidth}px` }"
      />
    </div>
    <div class="card-body py-4 px-8">
      <h4 class="text-h6 text-body-darkest mb-4">
        {{ title }}
      </h4>
      <template v-if="useHtml">
        <p
          v-for="(paragraph, index) in text"
          :key="index"
          v-html="paragraph"
        ></p>
      </template>
      <template v-else>
        <p v-for="(paragraph, index) in text" :key="index">
          {{ paragraph }}
        </p>
      </template>
      <did-not-know-that-fact-button
        :fact="title"
        class="mt-8"
        :assessmentType="assessmentType"
      />
    </div>
  </div>
</template>

<script>
import DidNotKnowThatFactButton from "./DidNotKnowThatFactButton.vue";
export default {
  name: "ReportInfoCard",
  components: {
    DidNotKnowThatFactButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    illustration: {
      type: String,
      required: true
    },
    text: {
      type: Array,
      required: true
    },
    assessmentType: {
      type: String,
      required: true
    },
    useHtml: {
      type: Boolean,
      default: false
    },
    imageBackgroundColour: {
      type: String,
      default: ""
    },
    imageWidth: {
      type: Number,
      default: 120
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-card-light {
  background: white;
  border-color: hsla(0, 0%, 0%, 0.1);
  overflow: hidden;
}

.card-image {
  img {
    display: block;
    width: 100%;
  }
}
</style>
